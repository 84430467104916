<template>
    <div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div :class="[ !isCompany ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' :  'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4']">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0" id="allotment-tab"
                       data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                       aria-disabled="true">{{ t('GENERAL.PERSONAL_DATA') }}</a>
                </li>
            </div>
            <div :class="[!isCompany ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3': 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4']">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 1 }"
                       @click.stop.prevent="activeTab = 1"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS') }}</a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"
            v-if="!isCompany">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 2 }"
                       @click.stop.prevent="activeTab = 2"
                       id="addresscompany-tab"
                       data-toggle="tab"
                       href="#addressCompany"
                       role="tab"
                       aria-controls="addressCompany"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS_COMPANY') }}</a>
                </li>
            </div>
            <div :class="[!isCompany ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3': 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4']">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 3 }" @click.stop.prevent="activeTab = 3"
                       id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document"
                       aria-disabled="true">{{ t('CLIENTS.DOCUMENT') }}</a>
                </li>
            </div>
        </ul>
        <form @submit.prevent="create">
            <div class="tab-content modal-row2 modal-height-max" id="myTabContent" v-if="client">
                <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="allotment"
                     role="tabpanel" aria-labelledby="allotment-tab">
                    <div class="modal-body">
                        <div class="row gutters">
                            <div v-if="!this.isCompany" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="name">{{ t('GENERAL.NAME') }}: </label>
                                    <input type="text" id="name" name="name" v-model="client.name" class="form-control"
                                           placeholder="Nome Completo" disabled>
                                </div>
                                <div class="form-group">
                                    <label for="email">{{ t('GENERAL.EMAIL') }}: </label>
                                    <input type="text" id="email" name="email" v-model="client.email"
                                           class="form-control"
                                           placeholder="Endereço de e-mail" disabled>
                                </div>
                            </div>
                            <div v-else class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="name">{{ t('GENERAL.NAME') }}: </label>
                                    <input type="text" id="corporate_name" name="name" v-model="client.name"
                                           class="form-control"
                                           placeholder="Nome Completo" disabled>
                                </div>
                                <div class="form-group">
                                    <label for="email">{{ t('GENERAL.EMAIL') }}: </label>
                                    <input type="text" id="email_company" name="email" v-model="client.email"
                                           class="form-control"
                                           placeholder="Endereço de e-mail" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div v-if="this.isCompany" class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="taxpayer">CNPJ: </label>
                                    <input type="text" id="taxpayer2" name="taxpayer" v-model="client.taxpayer"
                                           class="form-control"
                                           placeholder="000.000.000-00" disabled>
                                </div>
                            </div>
                            <div v-else class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="taxpayer">CPF: </label>
                                    <input type="text" id="taxpayer" name="taxpayer" v-model="client.taxpayer"
                                           class="form-control"
                                           placeholder="000.000.000-00" disabled>
                                </div>
                            </div>
                            <div v-if="!this.isCompany" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="rg">RG: </label>
                                    <input type="text" id="rg" name="rg" v-model="client.rg" class="form-control"
                                           maxlength="15"
                                           disabled>
                                </div>
                            </div>
                            <div v-if="this.isCompany" class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="rg">Nome Fantasia: </label>
                                    <input type="text" id="trade_name" name="trade_name" v-model="client.trade_name"
                                           class="form-control"
                                           disabled>
                                </div>
                            </div>
                            <div v-if="this.isCompany" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label for="creci">{{ t('CLIENTS.CRECI') }}: </label>
                                    <input type="text" id="creci" name="creci" disabled
                                           v-model="client.creci" placeholder="Creci"
                                           class="form-control">
                                </div>
                            </div>
                            <div v-if="this.isCompany" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label>{{ t('GENERAL.RESPONSABLE') }}: </label>
                                    <input type="text" disabled
                                           v-model="client.responsableName" placeholder="Responsavel"
                                           class="form-control">
                                </div>
                            </div>
                            <div v-show="!this.isCompany" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="dispatching_agency">{{ t('CLIENTS.DISPATCH_AGENCY') }}: </label>
                                    <input type="text" id="dispatching_agency" name="dispatching_agency"
                                           v-model="client.dispatching_agency" class="form-control" disabled>
                                </div>
                            </div>
                            <div v-show="!this.isCompany" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="form-group">
                                    <label for="dispatch_date">{{ t('CLIENTS.DISPATCH_DATE') }}: </label>
                                    <input type="text" id="dispatch_date" name="dispatch_date"
                                           v-model="client.dispatch_date"
                                           placeholder="dd-mm-aaaa"
                                           class="form-control" disabled>
                                </div>
                            </div>
                        </div>
                        <div v-show="!this.isCompany" class="row gutters">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div class="form-group">
                                    <label for="maritalstatus">{{ t('CLIENTS.MARITAL_STATUS') }}: </label>
                                    <input type="text" id="maritalstatus" name="maritalstatus"
                                           v-if="client?.marital_statuses?.name"
                                           v-model="client.marital_statuses.name"
                                           class="form-control" disabled>
                                    <input type="text" id="maritalstatus" name="maritalstatus"
                                           v-else
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div class="form-group">
                                    <label for="conjuge">{{ t('CLIENTS.CONJUGE') }}: </label>
                                    <input type="text" id="conjuge" name="conjuge"
                                           v-model="client.spouseName"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div class="form-group">
                                    <label for="nationality">{{ t('CLIENTS.NATIONALITY') }}: </label>
                                    <input type="text" id="nationality" name="nationality" v-model="client.nationality"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div class="form-group">
                                    <label for="naturalness">{{ t('CLIENTS.BIRTH_PLACE') }}: </label>
                                    <input type="text" id="naturalness" name="naturalness" v-model="client.naturalness"
                                           class="form-control" disabled>
                                </div>
                            </div>
                        </div>
                        <div v-show="!this.isCompany" class="row gutters">
                            <div v-if="!this.isCompany" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div class="form-group ">
                                    <label for="income">Renda: </label>
                                    <input type="text"
                                           id="income"
                                           name="income"
                                           v-money3="valueConfig"
                                           v-model="client.income"
                                           class="form-control" disabled
                                           maxlength="15">
                                </div>
                            </div>
                            <div v-if="!this.isCompany" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div class="form-group ">
                                    <label for="income_family">Renda Familiar: </label>
                                    <input type="text" id="income_family"
                                           name="income_family"
                                           v-money3="valueConfig"
                                           v-model="client.income_family"
                                           class="form-control" disabled
                                           maxlength="15">
                                </div>
                            </div>
                            <div v-if="!this.isCompany" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div class="form-group ">
                                    <label for="work_on_company">Empresa em que trabalha: </label>
                                    <input type="text" id="work_on_company"
                                           name="work_on_company" v-model="client.work_on_company"
                                           class="form-control" disabled
                                           maxlength="128">
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="mother_name">{{ t('CLIENTS.MOTHER_NAME') }}: </label>
                                    <input type="text" id="mother_name" name="mother_name" v-model="client.mother_name"
                                           class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="father_name">{{ t('CLIENTS.FATHER_NAME') }}: </label>
                                    <input type="text" id="father_name" name="father_name" v-model="client.father_name"
                                           class="form-control" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                                <phone :phonesIndex="client.phones" :isView="true"></phone>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                <div class="form-group">
                                    <label for="signature">Observação: </label>
                                    <editor :init="configTinyMce" v-model="client.description" :disable="true"
                                            tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                    >
                                    </editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }" id="localization"
                     role="tabpanel" aria-labelledby="localization-tab">
                    <address-generic v-if="address" :addressIndex="address" :isView="true"/>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab === 2, show: activeTab === 2 }"
                     id="comercialAddress" v-if="!isCompany"
                     role="tabpanel" aria-labelledby="comercialaddress-tab">
                    <address-generic v-if="activeTab == 2" :isView="true" :addressIndex="comercialAddress"
                                     @update="updateComerciaAddress($event)"/>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 3, show: activeTab == 3 }" id="document"
                     role="tabpanel" aria-labelledby="document-tab">
                    <div class="row w-100 ml-2"  v-if="client?.documents?.length">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row pl-4">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.NAME')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.TYPE_ANEX')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                <div class="font-weight-bolder mt-2 ml-3 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.SIZE')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">
                                    {{ this.t('CLIENTS.DOCUMENTS.DOWNLOAD') }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2"
                             v-for="(document, key) in client.documents" :key="key"
                             :class="key != 0 ? 'border-top2': ''">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="list-primary  text-left">
                                    {{ document.name }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <Select2 :settings="{width: '100%'}" :options="optionsDriveType"
                                         placeholder="Selecione um Tipo de Anexo"
                                         disabled
                                         :id="`typeAnex${key}`" :name="`typeAnex${key}`"
                                         v-model="document.drive_files_type_id"
                                ></Select2>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="list-primary  text-left">
                                    {{ formatSize(document.file_size) }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="list-primary  text-left">
                                    <i class="icon-download pl-3" @click="downloadDocument(document)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card"><div class="card-body text-center"><h6>Nenhum Anexo Vinculado</h6></div></div>
                </div>
            </div>
            <div class="modal-footer" style="justify-content: end">
                <div>
                    <button class="btn btn-secondary mr-2" type="button" v-if="activeTab != 0" @click="backTab()">
                        {{ t('ALLOTMENT.PREVIOUS') }}
                    </button>
                    <button class="btn btn-secondary" type="button"
                            v-if="activeTab != 3"
                            @click="next()">
                        {{ t('ALLOTMENT.NEXT') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import phone from '@/components/layouts/phoneComponent.vue';
import addressGeneric from '@/components/layouts/addressComponent.vue';
import Drive from "../../services/Drive";
import {Money3Directive} from 'v-money3';
import Editor from '@tinymce/tinymce-vue';
import DriveFilesType from '../../services/DriveFilesType';
import Select2 from 'vue3-select2-component';

export default {
    name: 'createClient',
    directives: {money3: Money3Directive},
    props: ['clientIndex', 'addressIndex'],
    mixins: [Validate],
    components: {
        phone,
        addressGeneric,
        Editor,
        Select2,
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            optionsDriveType: [],
            client: null,
            valueConfig: {
                suffix: '',
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                disabled: false,
                minimumNumberOfCharacters: 1,
            },
            comercialAddress: {},
            activeTab: 0,
            isCompany: false,
            taxpayer: 0,
            address: null,
            configTinyMce: {
                license_key: 'gpl',
                plugins: 'print preview paste searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern  quickbars emoticons',
                menubar: 'file edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
        }
    },

    mounted() {
        this.listFilesType();
        this.client = this.clientIndex;
        this.address = this.addressIndex;
        this.taxpayer = this.client.taxpayer;
        this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
    },

    watch: {
        clientIndex: function () {
            this.client = this.clientIndex;
            this.address = this.addressIndex;
            this.taxpayer = this.client.taxpayer;
            this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
            this.activeTab = 0;
        },

        taxpayer: function () {
            if (this.taxpayer)
                this.isCompany = this.taxpayer.length;

            (this.isCompany === 18) ? this.isCompany = true : this.isCompany = false;
        }
    },

    methods: {
        next()
        {
            if (this.isCompany && this.activeTab === 1){
                this.activeTab = 3;
            }else {
                this.activeTab++
            }
        },
        backTab()
        {
            if (this.isCompany && this.activeTab === 3){
                this.activeTab = 1
            }else {
                this.activeTab--
            }
        },
        listFilesType() {
            DriveFilesType.list().then((resp) => {
                if (resp?.data?.length) {
                    resp.data.forEach((i) => {
                        this.optionsDriveType.push({id: i.id, text: i.text})
                    })
                }
            })
        },
        downloadDocument(doc) {
            this.$store.commit('changeLoading', true);
            Drive.download(doc.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.name);
                link.click();
                this.$store.commit('changeLoading', false);
            }).catch(() => this.$store.commit('changeLoading', false));
        },
        formatSize(fileSize) {
            if (fileSize < 1000) return `${fileSize} Bytes`
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`
        },
    }
}
</script>

<style scoped>
.btn-holver {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.custom-checkbox {
    padding-left: 32px !important;
}

.pointer {
    cursor: pointer;
}
</style>