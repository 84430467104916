<template>
    <div>
        <contentCreatePersonModalDesktop v-if="!widthMd(600)" :modalIndex="this">
        </contentCreatePersonModalDesktop>
        <contentCreatePersonModalMobile v-if="widthMd(600)" :modalIndex="this">
        </contentCreatePersonModalMobile>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import Clients from '@/services/Clients';
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Seller from "@/services/Seller";
import moment from 'moment';
import {Money3Directive} from 'v-money3';
import Drive from "../../services/Drive";
import DriveFilesType from '../../services/DriveFilesType';
import AttributesValidationsMixin from '../../mixins/AttributesValidationsMixin';
import {mapState} from "vuex";
import City from '../../services/City';
import contentCreatePersonModalDesktop from '@/pages/client/.partials/contentCreatePersonModalDesktop';
import contentCreatePersonModalMobile from '@/pages/client/.partials/contentCreatePersonModalMobile';
import widthMd from '../../mixins/widthMd';
import maritalStatuses from '../../services/maritalStatuses';

export default {
    name: 'createClient',
    directives: {money3: Money3Directive},
    props: ['clientIndex', 'addressIndex', 'isRequiredData', 'isPhisicalIndex'],
    mixins: [Validate, AttributesValidationsMixin, widthMd],
    components: {
        contentCreatePersonModalDesktop,
        contentCreatePersonModalMobile,
    },
    computed: {
        hasDocument() {
            return this.client?.documents?.length;
        },
        ...mapState({
            itemsValidations: 'itemsValidations',
            itemsValidationsRequired: 'itemsValidationsRequired',
        }),
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            clearDataPhone: false,
            type: null,
            codeNumber: 55,
            country_acronym: 'BR',
            number: '',
            description: null,
            isPhisical: true,
            invalid: {},
            uploadFile: false,
            anex: {},
            optionsDriveType: [],
            optionsDriveTypeRequired: [],
            client: null,
            activeTab: 0,
            comercialAddress: {},
            address: null,
            isCompany: false,
            options: [],
            optionsMaritalStatus: [],
            configTinyMce: {
                selector: 'textarea',
                license_key: 'gpl',
                contextmenu: false,
                plugins: 'print preview searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern emoticons',
                menubar: 'edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                images_upload_url: '',
                setup: function (editor) {
                    editor.on('Paste', function (e) {
                        var items = e.clipboardData.items;
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].type.indexOf('image/') !== -1) {
                                e.preventDefault();
                                alert('Colar imagens não é permitido.');
                                return;
                            }
                        }
                    });
                    editor.on('drop', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        alert('Arrastar e soltar de imagens não é permitido.');
                    });
                },
                file_picker_callback: function () {
                    return false;
                },
                images_upload_handler: function (blobInfo, success, failure) {
                    failure('Upload de imagem desabilitado');
                },
                image_advtab: false,
                image_title: false,
                automatic_uploads: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            valueConfig: {
                suffix: '',
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                masked: true,
                min: 0,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 1,
            },
            setting: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 3 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                dropdownParent: '#profession_block',
                minimumInputLength: 3,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/profissoes/listar`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
            settingSpouse: {
                width: '100%',
                language: {
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    },
                    searching: function () {
                        return "Buscando...";
                    }
                },
                dropdownParent: '#spouse_block',
                minimumInputLength: 0,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list?spouse=true`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                },
                data: [
                    {id: 0, text: "Nenhum"}
                ],
                dropdownCssClass: 'select2-dropdown-with-none',
            },
            professions: [],
            isEdit: false,
            configEditor: {
                height: '200'
            },
            maritalStatusesOptions:[],
            maritalstatus: {
                width: '100%',
                dropdownParent: '#marital_status_block',
            },
            ajaxResponsable: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                dropdownParent: '#block_responsable',
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list?onlyResponsable=true`,
                    dataType: "json",
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map(({id, text: text}) => ({id, text}))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            },
        }
    },
    mounted() {
        this.loadMaritalStatus();
        if (!this.isPhisicalIndex) {
            this.isPhisical = false;
            this.isCompany = true;
        }
        this.listFilesType();
        this.client = this.clientIndex;
        this.$store.commit('changeLoading', true);
        if (this.clientIndex.taxpayer?.length) {
            this.isPhisical = (this.clientIndex.taxpayer.length <= 14);
            if (!this.isPhisical) {
                this.client.corporate_name = this.client.name;
                this.isCompany = true;
            }
        }
        this.address = this.addressIndex;
        this.genOptions();
        this.isEdit = this.clientIndex.id ? true : false;
        this.$store.commit('changeLoading', false);
        this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
    },
    watch: {
        isPhisicalIndex: function (val) {
            if (!val) {
                this.isPhisical = false;
                this.isCompany = true;
            }
        },
        clientIndex: function () {
            this.$store.commit('changeLoading', true);
            this.activeTab = 0;
            this.isCompany = false;
            this.client.phones = [];
            this.client = this.clientIndex;
            this.address = this.addressIndex;
            this.isEdit = this.clientIndex.id ? true : false;
            if (this.clientIndex.taxpayer?.length) {
                this.isPhisical = (this.clientIndex.taxpayer.length <= 14);
                if (!this.isPhisical) {
                    this.isCompany = true;
                }
            }
            this.comercialAddress = this.client.comercial_address ? this.client.comercial_address : {};
            !this.isPhisical ? this.client.corporate_name = this.clientIndex.name : null;
            this.$store.commit('changeLoading', false);
            this.clearDataPhone = false;
            this.type = null;
            this.number = '';
            this.description = null;
        }
    },
    methods: {
        selectMaritalStatus(m)
        {
            if (m.id === 'selecione'){
                this.client.marital_status_id = null;
            }
        },
        loadMaritalStatus()
        {
            maritalStatuses.list().then(r => {
                this.maritalStatusesOptions = r.data;
            });
        },
        cleanedPhone() {
            this.clearDataPhone = false;
        },
        alterDescription(val) {
            this.description = val;
        },
        alterType(val) {
            this.type = val;
        },
        alterNumber(val) {
            this.number = val.number;
            this.codeNumber = val.code_country;
            this.country_acronym = val.country_acronym;
        },
        validateDate(context) {
            if (context === 'birthday') {
                let valid = moment(this.client.birthday, 'DD-MM-YYYY').isValid();
                if (!valid) {
                    this.client.birthday = null;
                    this.invalid.birthday = 'Data Inválida'
                } else {
                    this.validateDateMin()
                    this.invalid.birthday = null;
                }
            } else if (context === 'dispatch_date') {
                let valid = moment(this.client.dispatch_date, 'DD-MM-YYYY').isValid();
                if (!valid) {
                    this.client.dispatch_date = null;
                    this.invalid.dispatch_date = 'Data Inválida'
                } else {
                    this.invalid.dispatch_date = null;
                }
            }
        },
        validateDateMin() {
            if (!this.client?.birthday) return;
            const [dia, mes, ano] = this.client.birthday.split("-");
            const anoNumerico = parseInt(ano, 10);
            if (anoNumerico < 1900) {
                this.client.birthday = `${dia}-${mes}-1900`;
            }
        },
        selectSpouse({id, text}){
            if (!id) {
                this.client.spouse_id = null;
                this.client.spouseName = null;
            } else {
                this.client.spouse_id = id;
                this.client.spouseName = text;
            }
        },
        selectedResponsableId({id, text}) {
            if (!id) {
                this.client.responsible_id = null;
                this.client.responsableName = null;
            } else {
                this.client.responsible_id = id;
                this.client.responsableName = text;
            }
        },
        next() {
            if (this.activeTab == 0) {
                this.validateSecondAbaClick();
            } else {
                this.validateLastAbaClick();
            }
        },
        async uploadDocument(file) {
            if (!file) return this.toast.error(this.t('CLIENTS.DOCUMENTS.FILE_EMPTY'));
            if (!this.anex.drive_files_type_id) return this.toast.error('Obrigatorio selecionar Tipo de Anexo');
            if (!this.client?.id) {
                if (!this.client?.documents?.length) {
                    this.client.documents = [];
                }
                await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                }).then(base64 => {
                    this.client.documents.push({
                        drive_files_type_id: this.anex.drive_files_type_id,
                        name: file.name,
                        file_size: file.size,
                        file: base64,
                        context_id: null,
                        context: 'clientes'
                    })
                });
                this.anex = {};
                return;
            }
            this.$store.commit('changeLoading', true);
            let formData = new FormData();
            formData.append('file', file);
            formData.append('context_id', this.client.id);
            formData.append('drive_files_type_id', this.anex.drive_files_type_id);
            formData.append('context', 'clientes');
            Drive.uploadDocument(formData).then((resp) => {
                if (resp.data.code) {
                    this.toast.success(resp.data.description);
                } else {
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.CREATED'));
                }
                if (!this.client?.documents?.length) {
                    this.client.documents = [];
                }
                this.client.documents.push(resp.data);
                this.anex = {};
                this.$store.commit('changeLoading', false);
            }).catch(err => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            }).finally(() => {
                this.$store.commit('changeLoading', false)
            })
        },
        setFile() {
            this.uploadFile = true;
        },
        listFilesType() {
            let vinculed_at = this.isPhisical ? 'fisica' : 'juridica';
            DriveFilesType.list(vinculed_at).then((resp) => {
                if (resp?.data?.length) {
                    resp.data.forEach((i) => {
                        if (i.required_on_person && this.isPhisical) {
                            this.optionsDriveTypeRequired.push(i);
                            this.optionsDriveType.push({id: i.id, text: `${i.text} *`});
                        }else if(i.required_on_person_juridical && !this.isPhisical){
                            this.optionsDriveTypeRequired.push(i);
                            this.optionsDriveType.push({id: i.id, text: `${i.text} *`});
                        } else {
                            this.optionsDriveType.push({id: i.id, text: i.text});
                        }
                    })
                }
            })
        },
        summernoteChange(newValue) {
            this.client.description = newValue;
        },

        validateSecondAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 1;
            }
        },
        validateFieldsTabeOne() {
            const tabOne = document.getElementById('tab-one');
            const selects = tabOne.querySelectorAll('select');
            const inputs = tabOne.querySelectorAll('input');
            const ids = [];
            let valid = true;
            inputs?.forEach(input => {
                ids.push(input.id);
            });
            selects?.forEach(input => {
                ids.push(input.id);
            });
            if (this.itemsValidationsRequired?.length && ids?.length) {
                ids.forEach(id => {
                    let iten = this.itemsValidationsRequired.find(i => i.name === id);
                    if (!iten) return
                    if (!this.client[iten.name]) {
                        document.getElementById(iten.name)?.classList?.add('is-invalid');
                        this.invalid[iten.name] = this.t('GENERAL.MSG.REQUIRED_FIELD');
                        valid = false;
                    } else {
                        this.invalid[iten.name] = false;
                        document.getElementById(iten.name)?.classList?.remove('is-invalid');
                    }
                })
            }
            if (this.type && this.number) {
                this.client.phones.push({
                    type: this.type,
                    number: this.number,
                    description: this.description
                })
                this.type = null;
                this.number = '';
                this.description = null;
                this.clearDataPhone = true;
            }
            if (this.isRequired('phones')) {
                if (!this.client.phones?.length) {
                    this.invalid.phones = 'Necessario informar um telefone';
                    valid = false;
                    document.getElementById('phones')?.classList?.add('is-invalid');
                } else {
                    this.invalid.phones = false;
                    document.getElementById('phones')?.classList?.remove('is-invalid');
                }
            }
            if (this.isRequired('description')) {
                if (!this.client.description) {
                    document.getElementById('description')?.classList?.add('is-invalid');
                    this.invalid.description = this.t('GENERAL.MSG.REQUIRED_FIELD');
                    valid = false;
                } else {
                    this.invalid.description = false;
                    document.getElementById('description')?.classList?.remove('is-invalid');
                }
            }
            if (!this.client.name && this.isPhisical) {
                document.getElementById('name')?.classList?.add('is-invalid');
                this.invalid.name = this.t('GENERAL.MSG.REQUIRED_FIELD');
                valid = false;
            } else if (!this.client.corporate_name && !this.isPhisical) {
                document.getElementById('name')?.classList?.add('is-invalid');
                this.invalid.name = this.t('GENERAL.MSG.REQUIRED_FIELD');
                valid = false;
            } else {
                this.invalid.name = false;
                document.getElementById('name')?.classList?.remove('is-invalid');
            }

            if (!valid) {
                this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
            }
            return valid;
        },
        isRequired(field) {
            return !!this.itemsValidationsRequired.find(i => i.name === field);
        },
        validateThirdAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 2;
            }
        },
        validateAnexAbaClick() {
            if (this.validateFieldsTabeOne()) {
                this.activeTab = 3;
            }
        },
        inputProfession(profession) {
            if (profession) {
                this.client.profession_id = profession;
            }
        },
        formatSize(fileSize) {
            if (fileSize < 1000) return `${fileSize} Bytes`
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`
        },
        genOptions() {
            this.options = ["MASCULINO", "FEMININO", "OUTROS"];
            this.optionsMaritalStatus = [
                "Solteiro(a)",
                "Casado(a)",
                "Separado(a)",
                "Divorciado(a)",
                "Viúvo(a)",
                "Amasiado(a)",
                "Separado(a) Judicialmente",
                "União estável",
                "Casado(a) no regime de comunhão parcial de bens",
                "Casado(a) no regime de comunhão universal de bens (Antes lei 6.515/77)",
                "Casado(a) no regime de comunhão universal de bens (Após lei 6.515/77)",
                "Casado(a) no regime de separação total de bens",
                "Casado(a) no regime de participação final nos aquestos"
            ]
        },

        backActiveTab() {
            if (this.activeTab === 3 && !this.isPhisical) {
                this.activeTab = 1;
            } else {
                this.activeTab--;
            }
        },
        validateLastAbaClick() {
            if (this.activeTab === 1 && !this.isPhisical) {
                this.activeTab = 3;
            } else {
                this.activeTab++;
            }
        },
        updateAddress(address) {
            this.client.address = address;
        },
        updateComerciaAddress(address) {
            this.client.comercial_address = address;
        },
        updatePhone(phones) {
            this.client.phones = phones;
        },
        createPhone(phones) {
            this.description = null;
            this.type = null;
            this.number = '';
            this.client.phones = phones;
        },
        validateTaxpayer(taxpayer) {
            if (this.isPhisical && taxpayer?.length === 14) {
                if (taxpayer?.length) {
                    if (this.validateCpf(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CPF inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            } else if (taxpayer?.length === 18) {
                if (taxpayer?.length) {
                    if (this.validateCNPJ(taxpayer)) {
                        this.invalid.taxpayer = false;
                        document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                    } else {
                        this.invalid.taxpayer = 'CNPJ inválido';
                        document.getElementById("taxpayer").classList.add("is-invalid");
                    }
                } else {
                    this.invalid.taxpayer = false;
                    document.getElementById("taxpayer")?.classList?.remove("is-invalid");
                }
            }
        },
        async findClient(taxpayer) {
            if (this.isPhisical && taxpayer.length !== 14) {
                return;
            } else if (!this.isPhisical && taxpayer.length !== 18) {
                return;
            }
            let tax = btoa(taxpayer);
            this.$store.commit("changeLoading", true);
            await Clients.showWhenCreate(tax).then(async (res) => {
                if (res.data.length !== 0) {
                    if (res.data.company_id === localStorage.getItem('companyId')) {
                        let resp = res.data;
                        this.client.taxpayer = resp.taxpayer;
                        this.isPhisical = (resp.taxpayer?.length <= 14)
                        this.$emit('to-update', res.data.id);
                    } else {
                        let resp = res.data;
                        this.client.taxpayer = resp.taxpayer;
                        this.client.name = resp.name;
                        this.client.email = resp.email;
                        this.client.rg = resp.rg;
                        this.client.dispatching_agency = resp.dispatching_agency;
                        this.client.dispatch_date = res.data.dispatch_date;
                        this.client.nationality = resp.nationality;
                        this.client.naturalness = resp.naturalness;
                        this.client.birthday = res.data.birthday;
                        this.client.father_name = resp.father_name;
                        this.client.sex = resp.sex;
                        this.client.profession_id = resp.professions.id
                        this.client.mother_name = resp.mother_name;
                        this.address = resp.address;
                        if (this?.address?.cep) {
                            this.client.address = this.address;
                        }
                        this.toast.success(this.t("GENERAL.MSG.CHECK_YOUR_DATA_AND_CONTINUE"), {timeout: false});
                        this.toast.success(this.t("GENERAL.MSG.CLIENT_LOCATED_IN_THE_DATABASE"));
                        this.client.phones = resp.phones;
                        this.$store.commit('changeLoading', false)
                    }
                } else {
                    taxpayer = taxpayer.replaceAll(".", "");
                    taxpayer = taxpayer.replaceAll("-", "");
                    taxpayer = taxpayer.replaceAll("/", "");
                    if (taxpayer.length === 14) {
                        this.$store.commit("changeLoading", true);
                        await Seller.loadCnpj(taxpayer).then(async (resp) => {
                            this.client.corporate_name = resp.data.razao_social_nome_empresarial;
                            this.client.email = resp.data.estabelecimento[0]?.correio_eletronico;
                            this.client.birthday = this.formaterBirthDay(resp.data.estabelecimento[0]?.data_inicio_atividades);
                            this.client.trade_name = resp.data.estabelecimento[0]?.nome_fantasia;
                            this.address.street = resp.data.estabelecimento[0]?.logradouro;
                            this.address.stateName = resp.data.estabelecimento[0]?.uf;
                            this.address.cep = resp.data.estabelecimento[0]?.cep;
                            this.address.neighborhood = resp.data.estabelecimento[0]?.bairro;
                            this.address.complement = resp.data.estabelecimento[0]?.complemento;
                            this.address.cityName = resp.data.estabelecimento[0]?.municipio.descricao;
                            this.address.number = resp.data.estabelecimento[0]?.numero;
                            if (this?.address?.cep) {
                                await City.listByCepDisp(this.address.cep).then(async (resp) => {
                                    if (!resp.data.erro) {
                                        this.address.city_id = resp.data.id;
                                        this.address.stateName = resp.data.name;
                                        this.address.cityName = resp.data.localidade;
                                        this.$emit('update', this.address);
                                    }
                                })
                                this.client.address = this.address;
                                if (!this.client?.address?.city_id) {
                                    this.client.address = {};
                                    this.address = {};
                                }
                            } else {
                                this.client.address = {};
                                this.address = {};
                            }
                            if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd1}${resp.data.estabelecimento[0]?.telefone1}`)) {
                                this.client.phones = [{
                                    number: (`(${resp.data.estabelecimento[0].ddd1}) ` + resp.data.estabelecimento[0].telefone1),
                                    type: '2'
                                }];
                            } else {
                                this.client.phones = [{
                                    number: (`(${resp.data.estabelecimento[0]?.ddd1}) ` + resp.data.estabelecimento[0]?.telefone1),
                                    type: '1'
                                }];
                            }
                            if (resp.data.estabelecimento[0]?.ddd2) {
                                if (await this.checkNumberPhone(`${resp.data.estabelecimento[0]?.ddd2}${resp.data.estabelecimento[0]?.telefone2}`)) {
                                    this.client.phones.push({
                                        number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                        type: '2'
                                    });
                                } else {
                                    this.client.phones.push({
                                        number: (`(${resp.data.estabelecimento[0]?.ddd2}) ` + resp.data.estabelecimento[0]?.telefone2),
                                        type: '1'
                                    });
                                }
                            }
                            this.$store.commit("changeLoading", false);
                        }).catch(() => {
                            this.$store.commit("changeLoading", false)
                        });
                    }
                }
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            }).finally(() => this.$store.commit("changeLoading", false))
        },
        async checkNumberPhone(number) {
            let isValid = false
            await Clients.numberValid(number).then(response => {
                isValid = response.data.numberExists;
            })
            return isValid;
        },
        formaterBirthDay(date) {
            if (!date) {
                return null;
            }
            date = date.split('/');
            return `${date[0]}-${date[1]}-${date[2]}`;
          //  return `${date[2]}-${date[1]}-${date[0]}`;
        },
        formatterValue(value) {
            if (!value) return value;
            value = value.replace('R$ ', '');
            value = value.replaceAll(".", "");
            value = value.replaceAll(",", ".");
            value = parseFloat(value).toFixed(2);
            if (value < 1) {
                return 0;
            }
            return value;
        },
        create() {
            if (this?.type && this.number?.length > 2) {
                if(!this.client?.phones || !Array.isArray(this.client.phones)){
                    this.client.phones = [];
                }
                this.client.phones?.push({
                    code_country: this.codeNumber,
                    country_acronym: this.country_acronym,
                    type: this.type,
                    number: this.number,
                    description: this.description
                });
                this.type = null;
                this.number = '';
                this.description = null;
                this.clearDataPhone = true;
            }
            if (this.client?.profession_id === "0") {
                this.client.profession_id = null;
            }
            if (this.optionsDriveTypeRequired?.length) {
                let invalidDrive = false;
                this.optionsDriveTypeRequired.forEach(i => {
                    let has = false;
                    this.client?.documents?.forEach(d => {
                        if (parseInt(d.drive_files_type_id) === i.id) {
                            has = true;
                        }
                    });
                    if (!has) {
                        this.toast.error(`Obrigatorio informar um Anexo do tipo: ${i.text}`);
                        invalidDrive = true;
                    }
                });
                if (invalidDrive) {
                    return;
                }
            }
            if (this.checkForm()) {
                this.$store.commit('changeLoading', true);
                this.client.income = this.formatterValue(this.client.income);
                this.client.income_family = this.formatterValue(this.client.income_family);
                if (this.client?.email) this.client.email = this.client.email.toLowerCase();
                if (this.client.id) {
                    if (this.client.corporate_name) {
                        this.client.name = this.client.corporate_name
                        delete this.client.corporate_name;
                    }
                    if (this.client.phones) {
                        this.client.phones = this.client.phones.map(item => {
                            if (!item.number) {
                                item.number = '0';
                            }
                            return item;
                        })
                    }
                    if (this.isPhisical) {
                        this.client.type = 'phisical';
                    } else this.client.type = 'juridical';
                    if (!this.client?.address?.city_id) {
                        this.client.address = {};
                        this.address = {};
                    }
                    Clients.update(this.client).then(() => {
                        this.resetData();
                        this.toast.success(this.t("GENERAL.MSG.UPDATE_SUCCESS"));
                        this.$store.commit('changeLoading', false);
                        document.getElementById('closeX').click();
                        this.$emit('update');
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                } else {
                    if (this.client.corporate_name) {
                        this.client.name = this.client.corporate_name
                    }
                    if (this.isPhisical) {
                        this.client.type = 'phisical';
                    } else this.client.type = 'juridical';
                    if (!this.client?.address?.cep?.length) {
                        delete this.client.address;
                    }
                    if (!this.client?.comercial_address?.cep?.length) {
                        delete this.client.comercial_address;
                    }
                    if (!this.client?.address?.city_id) {
                        this.client.address = {};
                        this.address = {};
                    }
                    Clients.create(this.client).then(() => {
                        this.resetData();
                        this.toast.success(this.t("GENERAL.MSG.REGISTER_SUCCESS"));
                        this.$store.commit('changeLoading', false);
                        document.getElementById('closeX').click();
                        this.$emit('update');
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                }
            }
        },
        resetData() {
            this.number = '';
        },
        checkForm() {
            if (this.itemsValidationsRequired.length) {
                if (!this.validateFieldsTabeOne()) return false;
                let havNotField = false;
                if (this.isRequired('address_id')) {
                    let adr = this.address;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.address = {};
                        this.invalid.address.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.address.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.address = false;
                    }
                }
                if (this.isRequired('comercial_address_id') && this.isPhisical) {
                    let adr = this.comercialAddress;
                    if (!adr.cep || !adr.street || !adr.number || !adr.neighborhood) {
                        this.invalid.addressComercial = {};
                        this.invalid.addressComercial.cep = !adr.cep ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.street = !adr.street ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.number = !adr.number ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        this.invalid.addressComercial.neighborhood = !adr.neighborhood ? this.t('GENERAL.MSG.REQUIRED_FIELD') : false;
                        havNotField = true;
                    } else {
                        this.invalid.addressComercial = false;
                    }
                }
                if (havNotField) {
                    this.toast.error(this.t('GENERAL.MSG.PLEASE_INFORM_ALL_FIELDS_REQUIRED'));
                    return false;
                }
            }

            if (!this.client.email) {
                this.client.email = null;
            } else {
                this.client.email = this.client.email.trim();
            }

            let name = '';
            if (this.isPhisical) {
                name = this.client.name;
            } else name = this.client.corporate_name;

            if (!name) {
                this.invalid.name = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length < 2) {
                this.invalid.name = this.t("GENERAL.MSG.MINIMUM_CHARACTER");
                document.getElementById("name").classList.add("is-invalid");
                return false;
            } else if (name.length > 255) {
                this.invalid.name = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("name")?.classList.add("is-invalid");
                return false;
            } else {
                this.invalid.name = false;
                document.getElementById("name")?.classList?.add("is-valid");
            }

            if (this.client.dispatching_agency && this.client.dispatching_agency.length > 15) {
              this.invalid.dispatching_agency = 'Máximo de 15 caracteres permitido';
              document.getElementById("dispatching_agency")?.classList?.add("is-invalid");
              return false;
            } else {
              this.invalid.dispatching_agency = false;
              document.getElementById("dispatching_agency")?.classList?.remove("is-invalid");
            }

            return true;
        },
        downloadDocument(doc) {
            this.$store.commit('changeLoading', true);
            Drive.download(doc.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.name);
                link.click();
                this.$store.commit('changeLoading', false);
            }).catch(() => this.$store.commit('changeLoading', false));
        },
        documentDelete(anex, key) {
            if (anex.id) {
                this.$store.commit('changeLoading', true);
                Drive.fileDelete(anex.id).then(() => {
                    this.client.documents = this.client.documents.filter(document => {
                        if (document.id !== anex.id) return document;
                    })
                    if (!this.client.documents.length) this.activeTab--;
                    this.toast.success(this.t('CLIENTS.DOCUMENTS.DELETE_SUCCESSFULL'));
                    this.$store.commit('changeLoading', false);
                }).catch(err => {
                    this.errorMsg(err);
                    this.$store.commit('changeLoading', false);
                });
            } else {
                this.client.documents = this.client.documents.filter((document, k) => {
                    if (k !== key) return document;
                })
            }
        }
    }
}
</script>

<style scoped>
.btn-holver {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}
.tox .tox-editor-header{
    z-index: 0 !important;
}
.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.custom-checkbox {
    padding-left: 32px !important;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 424px) {
    .mt-m-2 {
        margin-top: 1.1rem !important;
    }
}
</style>
